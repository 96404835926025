<template>
  <div>
    <v-app-bar
      app
      color="light-blue darken-4"
      dense
      dark
    >
      <v-img
        class="mx-1"
        src="@/assets/logo.png"
        max-height="40"
        max-width="40"
        contain
      ></v-img>

      <v-toolbar-title>
        <v-list-item>
          <v-list-item-content>
            <div>
              <p class="mt-1 mb-1 pa-0">
                <b>3NavPlanning</b>
              </p>
              <p class="mt-1 mb-1 pa-0" style="font-size: 12px">
                {{ locodeCode }}
              </p>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="pa-1 mt-0">
        <v-list-item v-if="date">
          <v-list-item-content style="float: right">
            <div class="title-date-container">
              <p class="mt-1 mb-1 pa-0 title-text">
                <b>{{ title ?? ""}}</b>
              </p>
              <p class="mt-1 mb-1 pa-0" style="font-size: 12px">
                <b>{{ $t("global.date") }}:</b>
                {{ date | formatDate }}
              </p>
            </div>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import configs from "@/helpers/configs";

export default {
  computed: {
    locodes() {
      return configs.getLocodes();
    },
    portLocode() {
      return this.$store.state.screenshots.portLocode ?? "PTFNC";
    },
    locodeCode() {
      let locode = this.locodes.find((l) => l.code === this.portLocode);
      return locode ? locode.name : "Porto do Funchal";
    },
    title() {
      return this.$store.state.screenshots.title;
    },
    date() {
      return this.$store.state.screenshots.date;
    },
  },
};
</script>

<style scoped>
.title-date-container {
  max-width: 200px;
  overflow: hidden;
  text-align: right;
}

.title-text {
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
