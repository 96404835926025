<template>
  <div class="v-application--wrap">
    <AppBar />
    <v-main>
      <transition name="fade-transform" mode="out-in">
        <v-container class="fill-height pa-0" fluid>
          <v-layout column>
            <v-flex xs12>
              <v-sheet class="main-content">
                <router-view></router-view>
              </v-sheet>
            </v-flex>
          </v-layout>
        </v-container>
      </transition>
    </v-main>
  </div>
</template>
<script>
import AppBar from "@/components/Public/AppBar";
export default {
  components: {
    AppBar,
  },
};
</script>

<style scoped>
.main-content {
  height: calc(100dvh - var(--toolbar-height));
  position: relative;
  overflow-y: auto;
}
</style>
